/* eslint-disable no-console */
import { ApolloClient, ApolloProvider, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { CssBaseline } from '@mui/material';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import StylesProvider from '@mui/styles/StylesProvider';
import { AuthenticationProvider } from '@onemedical/auth';
import createAuthenticatedHttpLink from '@onemedical/graphql-auth';
import { render } from 'react-dom';

import App from './App';
import hostedMicroFrontends from './hostedMicroFrontends';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    errorLink,
    createAuthenticatedHttpLink({ uri: process.env.REACT_APP_GRAPHQL_API_URL }),
  ]),
});

const scope = Array.from(
  new Set(
    Object.entries(hostedMicroFrontends).reduce(
      (acc, app) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (app[1] as any).oauthScope ? acc.concat((app[1] as any).oauthScope.split(' ')) : acc,
      ['openid', 'profile', 'email'],
    ),
  ),
).join(' ');

const generateClassName = createGenerateClassName({
  seed: 'management-container',
});

render(
  <AuthenticationProvider
    providerUrl={process.env.REACT_APP_AUTH_PROVIDER_URL!}
    clientID={process.env.REACT_APP_AUTH_CLIENT_ID!}
    audience="https://onemedical.com"
    scope={scope}
    callbackPath="/oauth"
    auth0Connection={process.env.REACT_APP_AUTH0_CONNECTION}
    auth0LogoutReturnTo={`${window.location.origin}/auth/logout`}
  >
    <ApolloProvider client={client}>
      <StylesProvider generateClassName={generateClassName}>
        <CssBaseline />
        <App />
      </StylesProvider>
    </ApolloProvider>
  </AuthenticationProvider>,
  document.getElementById('root'),
);
